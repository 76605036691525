const categories = [
  {
    name: "offers",
    label: "🔥 Προσφορές",
    description: "",
  },
  {
    name: "single_origin",
    label: "🌍 Μονοποικιλιακοί",
    description:
      "Οι μονοποικιλιακοί καφέδες προέρχονται από μία μόνο ποικιλία καφέ και προσφέρουν μοναδικά αρώματα και γεύσεις. Η χρέωση είναι επιπρόσθετη στην τιμή του καφέ.",
  },
  {
    name: "cold_coffees",
    label: "🥤 Κρύοι καφέδες",
    description: "Roasting Warehouse 100% Arabica",
  },
  {
    name: "hot_coffees",
    label: "☕ Ζεστοί καφέδες",
    description: "Roasting Warehouse 100% Arabica",
  },
  {
    name: "tortillas",
    label: "🌯 Κρύα σάντουιτς",
    description:
      "Ολες μας τα σάντουιτς φτιάχνονται κατόπιν παραγγελίας με τα πιο φρέσκα & αγνά υλικά.",
  },

  {
    name: "salads",
    label: "🥗 Σαλάτες",
    description:
      "Ολες μας οι σαλάτες φτιάχνονται κατόπιν παραγγελίας με τα πιο φρέσκα και αγνά λαχανικά και υλικά.",
  },
  {
    name: "handmade_sweets",
    label: "🍰 Χειροποίητα γλυκά",
    description:
      "Όλα τα χειροποίητα γλυκά παρασκευάζονται απο εμάς στο χώρο μας με τα πιο αγνά υλικά.",
  },
  {
    name: "juices",
    label: "🍎 Φυσικοί χυμοί",
    description:
      "Όλοι οι χυμοί, είτε δικής μας παρασκευής είτε της YumTales είναι 100% φυσικοί, δε περιέχουν ζάχαρη & συντηρητικά και είναι κατάλληλοι τόσο για vegeterians όσο και vegans.",
  },
  {
    name: "smoothies",
    label: "🍌 Smoothies",
    description:
      "Όλοι οι χυμοί, είτε δικής μας παρασκευής είτε της YumTales είναι 100% φυσικοί, δε περιέχουν ζάχαρη & συντηρητικά και είναι κατάλληλοι τόσο για vegeterians όσο και vegans.",
  },
  {
    name: "beverages",
    label: "🍹 Ροφήματα",
    description: "",
  },

  {
    name: "toasts",
    label: "🥪 Τοστ",
    description: "Διαθέσιμη η επιλογή λευκού ψωμιού ή ψωμιού ολικής άλεσης.",
  },

  {
    name: "energy_drinks",
    label: "⚡ Ενεργειακά ποτά",
    description: "",
  },
  {
    name: "soft_drinks",
    label: "🍸 Αναψυκτικά",
    description: "",
  },
  {
    name: "water",
    label: "💧 Νερά",
    description: "",
  },
];

export default categories;
